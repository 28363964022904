<template>
  <iframe :src="value" width="100%" height="100%" frameborder="0"
    allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture" loading="lazy"></iframe>
</template>

<script>
export default {
  name: "SpotifyFrame",

  props: {
    value: String,
  },
};
</script>
