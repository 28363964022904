<template>
  <v-textarea
    :value="value"
    placeholder="¿En qué estas pensando?"
    rows="2"
    auto-grow
    hint="¡Puedes pegar enlaces de YouTube o Spotify para compartirlos!"
    @input="(e) => $emit('input', e)"
    @paste="handlePaste"
  />
</template>

<script>
export default {
  name: "PostInput",

  props: {
    value: String,
  },

  methods: {
    handlePaste(event) {
      const clipboardData = event.clipboardData || window.clipboardData;
      const pastedText = clipboardData.getData("Text");
      if (this.isValidUrl(pastedText)) {
        event.preventDefault();
        this.handleUrl(pastedText);
      }
    },
    isValidUrl(text) {
      try {
        const url = new URL(text);
        return this.isYoutubeUrl(url) || this.isSpotifyUrl(url);
      } catch (_) {
        return false;
      }
    },
    isYoutubeUrl(url) {
      const youtubeWatchRegex =
        /^(https?:\/\/)?(www\.)?youtube\.com\/watch\?v=.+$/;
      const youtubeEmbedRegex =
        /^(https?:\/\/)?(www\.)?youtube\.com\/embed\/.+$/;
      return (
        youtubeWatchRegex.test(url.href) || youtubeEmbedRegex.test(url.href)
      );
    },
    /* https://www.youtube.com/watch?v=widNWpgRGWM */
    /* https://www.youtube.com/embed/9w7A4JCd-vk */
    isSpotifyUrl(url) {
      const spotifyTrackRegex =
        /^(https?:\/\/)?(open\.spotify\.com\/(intl-es\/)?track\/.+$)/;
      const spotifyEmbedRegex =
        /^(https?:\/\/)?(open\.spotify\.com\/embed\/track\/.+$)/;
      return (
        spotifyTrackRegex.test(url.href) || spotifyEmbedRegex.test(url.href)
      );
    },
    /* https://open.spotify.com/intl-es/track/2PKb7tFxkwMoS7Y6muOTAR?si=2bd73aa260a44ba1 */
    /* https://open.spotify.com/embed/track/5OKy5809rOuZGRiCyWwfZS?utm_source=generator */
    handleUrl(url) {
      const urlObj = new URL(url);
      if (this.isYoutubeUrl(urlObj)) {
        this.handleYoutubeUrl(urlObj);
      } else if (this.isSpotifyUrl(urlObj)) {
        this.handleSpotifyUrl(urlObj);
      }
    },
    handleYoutubeUrl(url) {
      if (url.pathname === "/watch") {
        const videoId = url.searchParams.get("v");
        if (videoId) {
          this.addYoutubeLink(videoId);
        }
      } else if (url.pathname.startsWith("/embed/")) {
        const videoId = url.pathname.split("/").pop();
        this.addYoutubeLink(videoId);
      }
    },
    handleSpotifyUrl(url) {
      const trackId = url.pathname.split("/").pop();
      this.addSpotifyLink(trackId);
    },
    addYoutubeLink(id) {
      const url = `https://www.youtube.com/embed/${id}`;
      this.$emit("addYoutubeLink", url);
    },
    addSpotifyLink(id) {
      const url = `https://open.spotify.com/embed/track/${id}?utm_source=generator&theme=0`;
      this.$emit("addSpotifyLink", url);
    },
  },
};
</script>
