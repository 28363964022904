<template>
  <v-card class="glass">
    <!-- Toolbar -->
    <v-card-actions>
      <v-list-item>
        <v-list-item-avatar>
            <UserImage :userid="value.created.user" small></UserImage>
        </v-list-item-avatar>

        <v-list-item-content>
          <v-list-item-title>
            {{ value.created.displayName }}
          </v-list-item-title>
          <v-list-item-subtitle class="caption">
            {{ getFormattedDate(value.created.date) }}
          </v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

      <v-spacer />

      <v-menu bottom left offset-y v-if="isUserAdminOrSU">
        <template v-slot:activator="{ on, attrs }">
          <v-btn v-bind="attrs" v-on="on" text :large="$vuetify.breakpoint.mdAndUp" :loading="loading.edit">
            <v-icon> mdi-dots-vertical </v-icon>
          </v-btn>
        </template>

        <v-list class="py-0">
          <v-list-item @click="$emit('openEdit')">
            <v-list-item-icon>
              <v-icon>mdi-pencil</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Editar</v-list-item-title>
          </v-list-item>

          <v-list-item @click="deletePost">
            <v-list-item-icon>
              <v-icon>mdi-delete</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Eliminar</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-card-actions>

    <v-divider />

    <v-card-text>
      <span class="text--primary" v-html="formattedContent"></span>
    </v-card-text>


    <!-- Media -->
    <MediaCarousel v-if="mediaLength" :attachments="value.attachments" :youtubeLinks="value.youtubeLinks"
      :spotifyLinks="value.spotifyLinks" viewOnly />

    <v-divider v-if="mediaLength" />

    <!-- Actions -->
    <v-card-actions>
      <v-btn text :large="$vuetify.breakpoint.mdAndUp" @click="toggleLike(value)" :loading="loading.like">
        <v-icon left :color="isLikedByMe ? 'red' : null">
          {{ isLikedByMe ? "mdi-heart" : "mdi-heart-outline" }}
        </v-icon>
        <span :class="{ 'red--text': isLikedByMe }">
          {{ value.likes.length }}
        </span>
      </v-btn>

      <!--  <v-btn text :large="$vuetify.breakpoint.mdAndUp" @click="() => { }">
        <v-icon left> mdi-message-outline </v-icon>
        <span>
          {{ value.likes.length }}
        </span>
      </v-btn> -->

      <v-spacer />

<!--       <VisibilityDisplay v-if="isUserAdminOrSU" :value="value.visibility" />
 -->    </v-card-actions>
  </v-card>
</template>

<script>
import UserImage from "@/components/profile/UserImage.vue";
import { formatDate } from "@/utils";
import MediaCarousel from "../media/MediaCarousel.vue";
import VisibilityDisplay from "../visibility/VisibilityDisplay.vue";

import { getFirestore, deleteDoc, doc, runTransaction } from "firebase/firestore";
import { ref as storageRef, getDownloadURL, uploadBytesResumable, getStorage, deleteObject } from "firebase/storage";


export default {
  name: "PostDisplay",
  data() {
    return {
      loading: {
        like: false,
        edit: false,
      },

    };
  },
  components: {
    UserImage,
    MediaCarousel,
    VisibilityDisplay,
  },

  props: {
    value: Object,
  },

  computed: {
    isUserAdminOrSU() {
      const role = this.$store.state.Auth.token.claims.type;
      return role && ["admin", "superuser"].includes(role);
    },
    isLikedByMe() {
      return this.value.likes.includes(
        this.$store.state.Auth.token.claims.user_id
      );
    },
    formattedContent() {
      return this.value.content.replace(/\n/g, "<br />");
    },
    mediaLength() {
      const media = [
        ...this.value.attachments,
        ...this.value.youtubeLinks,
        ...this.value.spotifyLinks,
      ];

      return media.length;
    },
  },

  methods: {
    getFormattedDate(date) {
      return formatDate(date);
    },
    async toggleLike(post) {

      this.loading.like = true;
      let newLikes = [];

      newLikes = [...post.likes] || [];
      const likeIndex = newLikes.findIndex(
        (e) => e === this.$store.state.Auth.token.claims.user_id
      );

      const db = getFirestore();
      const postRef = doc(db, "posts", post.id);
      await runTransaction(db, async (transaction) => {
        const postDoc = await transaction.get(postRef);
        if (!postDoc.exists()) {
          throw "Document does not exist!";
        }

        //postLIkes
        newLikes = [...postDoc.data().likes] || [];

        if (likeIndex !== -1) {
          newLikes.splice(likeIndex, 1);
        } else {
          newLikes.push(this.$store.state.Auth.token.claims.user_id);
        }

        transaction.update(postRef, { likes: newLikes });
      });

      this.$emit("update", { ...this.value, likes: newLikes });
      this.loading.like = false;
    },
    async deletePost() {
      try {
        const confirm = await this.$confirm(
          "¿Está seguro de que desea eliminar esta publicación?",
          {
            color: "error",
            title: "Advertencia",
            icon: "mdi-alert-circle",
            buttonTrueText: "Confirmar",
          }
        );


        if (!confirm) return;
        this.loading.edit = true;



        // Delete attachments from storage using promise.all and deleteObject
        const storage = getStorage();

        if (this.value.attachments.length) {
          await Promise.all(
            this.value.attachments.map(async (attachment) => {
              const fileRef = storageRef(storage, `attachments/${this.value.id}/${attachment.name}`);
              await deleteObject(fileRef);
            })
          );
        }

        const db = getFirestore();

        await deleteDoc(doc(db, "posts", this.value.id));

        this.$emit("delete", this.value);

        this.$notify({
          title: "Publicación eliminado",
          text: "Tu publicación ha sido eliminado con éxito",
          type: "success",
        });
      } catch (e) {
        this.$notify({
          title: "Error",
          text: "Ha ocurrido un error al eliminar la publicación",
          type: "error",
        });
      } finally {
        this.loading.edit = false;
      }
    },
  },
};
</script>
