<template>
  <v-card class="d-flex align-center fill-height" flat tile>
    <v-img v-if="!viewOnly" :src="value.type.startsWith('image/') ? value.url : value.thumbnail" :alt="value.name"
      width="100%" max-height="400" contain />
    <v-img v-if="value.type.startsWith('image/')" :src="value.url" :alt="value.name" width="100%" max-height="400"
      height="100%" contain />
    <video v-if="viewOnly && !value.type.startsWith('image/')" :src="value.url" controls
      style="width: 100%; height: 100%"></video>

    <!--  <v-container
      v-if="!viewOnly || (viewOnly && value.type.startsWith('image/'))"
      class="text-caption white--text d-flex align-center"
      dark
      style="
        position: absolute;
        bottom: 0;
        background: rgba(0, 0, 0, 0.7);
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      "
    >
      <v-icon dark class="mr-2">
        {{
          value.type.startsWith("image/")
            ? "mdi-image-outline"
            : "mdi-video-outline"
        }}
      </v-icon>
      {{ value.name }}
    </v-container> -->
  </v-card>
</template>

<script>
export default {
  name: "AttachmentFrame",

  props: {
    value: Object,
    viewOnly: Boolean,
  }
};
</script>
