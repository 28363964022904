<template>
  <v-card class="d-flex align-center grey darken-4 fill-height" flat tile>
    <iframe
      :src="value"
      width="100%"
      height="100%"
      frameborder="0"
      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
      referrerpolicy="strict-origin-when-cross-origin"
      allowfullscreen
    ></iframe>
  </v-card>
</template>

<script>
export default {
  name: "YoutubeFrame",

  props: {
    value: String,
  },
};
</script>
