<template>
  <div>
    <v-btn icon color="primary" @click="$refs.fileInput.$refs.input.click()">
      <v-icon dark> mdi-attachment </v-icon>
    </v-btn>

    <v-file-input
      v-show="false"
      v-model="input"
      ref="fileInput"
      multiple
      accept="image/jpeg,image/png,video/mp4"
      @change="handleChange"
    />
  </div>
</template>

<script>
export default {
  name: "AttachmentsInput",

  props: {
    value: Array,
  },

  data() {
    return {
      input: [],
    };
  },

  methods: {
    async handleChange(files) {
      if (files && files.length && this.value.length < 5) {
        let newFiles = await this.mapNewFiles(files);
        let attachments = [...this.value, ...newFiles];

        if (attachments.length > 5) {
          attachments = attachments.slice(0, 5);
        }

        this.$emit("input", attachments);
        this.input = [];
      }
    },
    async mapNewFiles(files) {
      const mappedFiles = await Promise.all(
        files.map(async (e) => {
          const file = {
            name: e.name,
            type: e.type,
            url: URL.createObjectURL(e),
          };

          if (file.type.startsWith("video/")) {
            const thumbnail = await this.generateVideoThumbnail(file.url);
            file.thumbnail = thumbnail;
          }

          return file;
        })
      );

      return mappedFiles;
    },
    generateVideoThumbnail(url) {
      return new Promise((resolve) => {
        const video = document.createElement("video");
        video.src = url;

        video.addEventListener("loadeddata", () => {
          video.currentTime = 1; // Seek to 1 second into the video
        });

        video.addEventListener("seeked", () => {
          const canvas = document.createElement("canvas");
          canvas.width = video.videoWidth;
          canvas.height = video.videoHeight;
          const context = canvas.getContext("2d");
          context.drawImage(video, 0, 0, canvas.width, canvas.height);
          const thumbnail = canvas.toDataURL("image/png");
          resolve(thumbnail);
        });
      });
    },
  },
};
</script>
