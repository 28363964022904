<template>
  <v-row no-gutters class="justify-center" style="position: relative">
    <v-carousel v-model="page" :continuous="false" height="100%" show-arrows-on-hover :hide-delimiters="mediaLength < 2"
      v-bind:style="{ height: `${carouselHeight}` }">
      <v-carousel-item v-if="attachments.length" v-for="(item, index) in attachments" :key="`attachment-${index}`">
        <div class="fill-height" style="position: relative">
          <AttachmentFrame :value="item" :viewOnly="viewOnly" />

          <v-btn v-if="!viewOnly" class="elevation-0" color="error" dark fab x-small
            style="position: absolute; top: 8px; right: 8px" @click="$emit('deleteAttachment', index)">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>
      </v-carousel-item>

      <v-carousel-item v-if="youtubeLinks.length" v-for="(item, index) in youtubeLinks" :key="`youtubeLink-${index}`">
        <div class="fill-height" style="position: relative">
          <YoutubeFrame :value="item" />

          <v-btn v-if="!viewOnly" class="elevation-0" color="error" dark fab x-small
            style="position: absolute; top: 8px; right: 8px" @click="$emit('deleteYoutubeLink', index)">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>
      </v-carousel-item>

      <v-carousel-item v-if="spotifyLinks.length" v-for="(item, index) in spotifyLinks" :key="`spotifyLink-${index}`">
        <div class="fill-height pa-4 pb-8 pt-0" style="position: relative">
          <SpotifyFrame :value="item" />

          <v-btn v-if="!viewOnly" class="elevation-0" color="error" dark fab x-small
            style="position: absolute; top: 8px; right: 8px" @click="$emit('deleteSpotifyLink', index)">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>
      </v-carousel-item>
    </v-carousel>

    <v-chip v-if="mediaLength > 1" class="white--text" pill style="
        position: absolute;
        top: 8px;
        left: 8px;
        background-color: rgba(0, 0, 0, 0.7);
      ">
      {{ page + 1 }} / {{ mediaLength }}
    </v-chip>

    <!-- <v-toolbar
      v-if="mediaLength > 1"
      class="d-flex justify-center"
      color="transparent"
      flat
      dense
    >
      <div class="mr-4 d-flex align-center" v-if="attachments.length">
        <v-icon class="mr-2">mdi-attachment</v-icon>
        {{ attachments.length }} / 5
      </div>
      <div class="mr-4 d-flex align-center" v-if="youtubeLinks.length">
        <v-icon class="mr-2" color="#FF0000">mdi-youtube</v-icon>
        {{ youtubeLinks.length }}
      </div>
      <div class="mr-4 d-flex align-center" v-if="spotifyLinks.length">
        <v-icon class="mr-2" color="#1DB954">mdi-spotify</v-icon>
        {{ spotifyLinks.length }}
      </div>
    </v-toolbar> -->
  </v-row>
</template>

<script>
import AttachmentFrame from "./AttachmentFrame.vue";
import SpotifyFrame from "./SpotifyFrame.vue";
import YoutubeFrame from "./YoutubeFrame.vue";

export default {
  name: "MediaCarousel",

  components: {
    AttachmentFrame,
    SpotifyFrame,
    YoutubeFrame,
  },

  props: {
    attachments: Array,
    youtubeLinks: Array,
    spotifyLinks: Array,
    viewOnly: Boolean,
  },

  data() {
    return {
      page: 0,
    };
  },

  computed: {
    mediaLength() {
      const media = [
        ...this.attachments,
        ...this.youtubeLinks,
        ...this.spotifyLinks,
      ];

      return media.length;
    },
    carouselHeight() {
      const heights = {
        xs: "100%",
        sm: "100%",
        md: "400px",
        lg: "400px",
        xl: "400px",
      };
      return heights[this.$vuetify.breakpoint.name];
    },
  },
};
</script>
