<template>
  <v-chip class="mx-2 px-4" outlined v-bind="$attrs" v-on="$listeners">
    <v-icon color="primary" left>
      {{
        value.includes("all")
          ? "mdi-web"
          : value.length
          ? "mdi-account-multiple"
          : "mdi-lock-outline"
      }}
    </v-icon>
    {{
      value.includes("all")
        ? "Público"
        : value.length
        ? `${value.length} Usuario${value.length > 1 ? "s" : ""}`
        : "Privado"
    }}
  </v-chip>
</template>

<script>
export default {
  name: "VisibilityDisplay",

  props: { value: Array },
};
</script>
