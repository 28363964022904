<!-- src/components/community/CommunityForm.vue -->
<template>
  <div>
    <!-- Button to open the create community dialog -->

    <v-tooltip bottom>
      <template #activator="{ on }">
        <v-btn icon v-on="on" color="success" @click="dialog = true">
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </template>
      <span>Nueva Comunidad</span>
    </v-tooltip>
    <!-- Dialog containing the community creation form -->
    <v-dialog v-model="dialog" max-width="500px">
      <v-card>
        <v-card-title>Nueva Comunidad
          <v-spacer/>
          <v-btn icon @click="closeDialog">
            <v-icon>mdi-close</v-icon>  
          </v-btn>


        </v-card-title>
        <v-divider/>
        <v-card-text class="pt-4">
          <v-form ref="form" @submit.prevent="createCommunity">
            <v-text-field
                rounded filled class="rounded-lg"
              label="Nombre de la Comunidad"
              v-model="community.name"
              :rules="[v => !!v || 'El nombre es requerido']"
            ></v-text-field>
            <v-select 
            rounded filled class="rounded-lg"
              :items="availableUsers"
              item-text="displayName"
              item-value="id"
              label="Miembros"
              v-model="community.members"
              multiple
              
              :rules="[v => v.length > 0 || 'Debe seleccionar al menos un usuario']"
            ></v-select>
          </v-form>
        </v-card-text>
                <v-divider/>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn text @click="closeDialog">Cancelar</v-btn>
          <v-btn color="success" @click="createCommunity" :loading="loading">
            <v-icon left>
                mdi-check
            </v-icon>
            Iniciar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { addDoc, collection, doc, updateDoc, getFirestore } from "firebase/firestore";
import { arrayUnion } from "firebase/firestore"; // For array operations

export default {
  name: "CommunityForm",
  props: {
    availableUsers: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
                loading: false,

      dialog: false,
      community: {
        name: "",
        members: [], // Array of user IDs
      },
    };
  },
  methods: {
    closeDialog() {
      this.dialog = false;
      this.resetForm();
    },
    async createCommunity() {
      if (this.$refs.form.validate()) {
        try {
            this.loading=true;
          const db = getFirestore();

          // Add the community to Firestore
          const docRef = await addDoc(collection(db, "communities"), {
            name: this.community.name,
            members: this.community.members,
          });

          // Update each user's enabledCommunities field
          for (const userId of this.community.members) {
            const userRef = doc(db, "users", userId);
            await updateDoc(userRef, {
              enabledCommunities: arrayUnion(docRef.id),
            });
          }
          this.$emit("create", { id: docRef.id, ...this.community });

          
          this.resetForm();
          this.dialog = false;
          this.$notify({
            title: "Comunidad creada",
            text: "La nueva comunidad ha sido creada exitosamente",
            type: "success",
          });
        } catch (error) {
          console.error("Error creating community:", error);
          this.$notify({
            title: "Error",
            text: "Ocurrió un error al crear la comunidad.",
            type: "error",
          });
        }

        this.loading = false;
      }
    },
    closeDialog() {
      this.dialog = false;
      this.resetForm();
    },
    resetForm() {
      this.community.name = "";
      this.community.members = [];
      if (this.$refs.form) {
        this.$refs.form.resetValidation();
      }
    },
  },
};
</script>
