<template>
  <v-container class="pa-0">
    <v-list-item class="py-4">
      <v-text-field v-model="search" label="Buscar" outlined clearable hide-details class="rounded-lg" />
    </v-list-item>

    <v-list flat class="pa-0">
      <v-list-item-group :value="[]">
        <v-list-item @click="toggleAll">
          <template>
            <v-list-item-action>
              <v-checkbox :input-value="isAllSelected" :indeterminate="!isAllSelected && value.length" />
            </v-list-item-action>

            <v-list-item-avatar>
              <v-icon> mdi-account-multiple </v-icon>
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title>Seleccionar todo</v-list-item-title>
            </v-list-item-content>
          </template>
        </v-list-item>
      </v-list-item-group>
    </v-list>

    <v-divider />

    <v-list :disabled="isAllSelected" class="pa-0 overflow-y-auto" max-height="300">
      <v-list-item-group :value="value" multiple color="primary" @change="(e) => $emit('input', e)">
        <template v-for="(item, index) in filteredUsers">
          <v-list-item :key="item.id" :value="item.id" :class="{ 'v-list-item--active': isAllSelected }">
            <template #default="{ active }">
              <v-list-item-avatar>

                <UserImage :userid="item.id" small></UserImage>

              </v-list-item-avatar>

              <v-list-item-content>
                <v-list-item-title v-text="item.displayName"></v-list-item-title>
              </v-list-item-content>

              <v-list-item-action>
                <v-checkbox :input-value="active || isAllSelected"></v-checkbox>
              </v-list-item-action>
            </template>
          </v-list-item>

          <v-divider v-if="index < availableUsers.length - 1" :key="`divider-${index}`"></v-divider>
        </template>
      </v-list-item-group>
    </v-list>

    <!-- No resuls -->
    <template v-if="!filteredUsers.length" class="pa-4">
      <v-container>
        <v-alert type="info" color="primary darken-2" class="ma-0">
          {{ search ? "No se encontraron usuarios!" : "No hay usuarios" }}
        </v-alert>
      </v-container>
    </template>
  </v-container>
</template>

<script>
import UserImage from '@/components/profile/UserImage.vue';


export default {
  name: "VisibilityForm",

  components: {
    UserImage,
  },

  props: {
    value: Array,
    availableUsers: Array,
  },

  data() {
    return {
      search: null,
    };
  },

  computed: {
    filteredUsers() {
      if (this.search) {
        return this.availableUsers.filter((e) =>
          e.displayName.toLowerCase().includes(this.search.toLowerCase())
        );
      } else {
        return this.availableUsers;
      }
    },
    isAllSelected() {
      return this.value.includes("all");
    },
  },

  methods: {
    toggleAll() {
      this.$emit("input", this.isAllSelected ? [] : ["all"]);
    },
  },
};
</script>
